<template>
  <v-col sm="12">
    <loader v-if="loading" :loading="loading" :color="companyData.companyColor"/>
  <v-col sm="12" v-else>
    <button
        class="tw-flex tw-items-center tw-mt-6 overview-btn"
        @click="backToPilots">
      <icons name="arrow-back" :color="companyData.companyColor" />
      <span class="tw-ml-4" :style="{ color: companyData.companyColor }">All Pilots</span>
    </button>

    <v-col sm="12" class="tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full">
      <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10">
        <p class="edit-header" :style="{ color: companyData.companyColor }">
          Edit Pilot
        </p>
        <button @click="handleShowDelete" v-if="!pilotData.fleetAssigned">
          <icons name="trash" :color="companyData.companyColor" />
        </button>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(updatePilot)">
      <v-row class="upload-div tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
        <v-col sm="12" lg="4" class="">
          <div class="form-label-div">
            <p class="label">Upload Photo</p>
            <p class="label-info">Choose an image as a profile</p>
          </div>
        </v-col>
        <v-col sm="12" lg="8" class="">
          <button
            class="button-logo-input"
            type="button"
            @click="onLogoFile()"
            :class="image ? 'border-0' : ''"
          >
            <v-progress-circular
              :size="100"
              :width="3"
              :color="companyData.companyColor"
              indeterminate
              style="position: absolute;"
              v-if="logoImageLoading"
            ></v-progress-circular>

            <img
                :src="image"
                alt=""
                class="button-logo-img"
                v-if="logoImage"
            />
            <img
                :src="pilotData.profileImage"
                alt=""
                class="button-logo-img"
                v-else-if="pilotData.profileImage"
            />
            <img
                src="../../../assets/camera.png"
                alt=""
                class="button-logo-input-img"
                v-else
            />
          </button>

          <input
            type="file"
            ref="logoImage"
            accept="image/*"
            style="display: none"
            @change="onLogoImagePicked"
          />
        </v-col>
      </v-row>

      <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
        <v-col sm="12" lg="4" class="">
          <div class="form-label-div">
            <p class="label">First Name</p>
            <p class="label-info">Type the First Name of the Pilot</p>
          </div>
        </v-col>
        <v-col sm="12" lg="8" class="">
          <ValidationProvider
            name="First Name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="Mason"
                solo
                hide-details
                type="text"
                required
                autofocus
                v-model="pilotData.firstname"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
        <v-col sm="12" lg="4" class="">
          <div class="form-label-div">
            <p class="label">Last Name</p>
            <p class="label-info">
              Type the Last Name of the Pilot
            </p>
          </div>
        </v-col>
        <v-col sm="12" lg="8" class="">
          <ValidationProvider
            name="Last Name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="Ryan"
                solo
                hide-details
                type="text"
                required
                v-model="pilotData.lastname"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

      <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
        <v-col sm="12" lg="4" class="">
          <div class="form-label-div">
            <p class="label">Email</p>
            <p class="label-info">
              Type the Email Address of the Pilot
            </p>
          </div>
        </v-col>
        <v-col sm="12" lg="8" class="">
          <ValidationProvider
            name="Pilot's Email"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-text-field
                placeholder="ryan@gmail.com"
                solo
                hide-details
                type="email"

                v-model="pilotData.email"
              ></v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Mobile</p>
                <p class="label-info">Type the Mobile Number of the Pilot</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <phone-number
                  :phone-number-exist="pilotData.phoneNumber"
                  @getNumber="getNumber" :required="false"
              />
            </v-col>
          </v-row>



      <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
        <v-col sm="12" lg="4" class="">
          <div class="form-label-div">
            <p class="label">Address</p>
            <p class="label-info">Enter the address in which the Pilot is located.</p>
          </div>
        </v-col>
        <v-col sm="12" lg="8" class="">
          <ValidationProvider name="Address" rules="required" v-slot="{ classes, errors }">
                <div class="tw-cursor-pointer" :class="classes" @click="handleShowAddressModal">
                  <v-text-field
                      placeholder="371,Borno Way, Alagomeji Yaba, Lagos" solo hide-details
                      type="text" required
                      v-model="getPilotAddress"></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
        </v-col>
      </v-row>




      <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
        <v-col sm="12" lg="4" class="">
          <div class="form-label-div">
            <p class="label">Terminal</p>
            <p class="label-info">
              Pilot Assigned Terminal.
            </p>
          </div>
        </v-col>
        <v-col sm="12" lg="8" class="">
          <ValidationProvider
            name="Terminal"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="" :class="classes">
              <v-combobox
                :items="terminals"
                placeholder="Choose Terminal"
                append-icon="mdi-chevron-down"
                item-text="terminalName"
                item-value="id"
                solo
                hide-details
                :color="companyData.companyColor"
                v-model="pilotData.terminalAssigned"
                disabled
              ></v-combobox>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>
      <div class="tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10">
        <v-btn
            text
            class="first-btn"
            :style="{
                  color: companyData.companyColor,
                  border: '1px solid' + companyData.companyColor,
                }" @click="backToPilots">
          Cancel
        </v-btn>
        <v-btn
            text
            class="second-btn"
            type="submit"
            :style="{ background: companyData.companyColor }"
            :loading="doneLoading">
          Update
        </v-btn>
      </div>
        </form>
      </validation-observer>
    </v-col>

  </v-col>
    <delete-modal @close="handleShowDelete"
                  description="Are you sure you want to delete this pilot"
                  @deleteAction="deletePilot"
                  :done-loading="deleteLoading"
                  :dialog="showDelete"/>

    <modal section="custom-address" :address="pilotData.address"
           :dialog="showAddressModal" @handleAddress="handleAddress"  @close="closeHandleModal"/>
  </v-col>
</template>

<script>
import {
  getAllCountries,
  getAllStatesByCountry,
  getAllCitiesInAState,
  uploadToCloudinary, getPilotById, getTransportCompanyTerminals, updatePilotById, deletePilotById
} from "../../../services/api/APIService";
import Loader from "../../reuseables/Loader";
import Icons from "../../reuseables/Icons";
import Lga from "../../../assets/lga";
import DeleteModal from "../../reuseables/DeleteModal";
import Modal from "@/components/reuseables/Modal.vue";
import PhoneNumber from "../../reuseables/PhoneNumber.vue";
export default {
  name: "EditBooking",
  components: {PhoneNumber, Modal, DeleteModal, Icons, Loader},
  data() {
    return {
      getCountriesLoading: false,
      getStatesLoading: false,
      getCitiesLoading: false,
      countries: [],
      states: [],
      cities: [],
      logoImage: "",
      logoImageUrl: "",
      logoImageLoading: false,
      image: "",
      companyData:{},
      pilotData:{
        address:{}
      },
      terminals:[],
      loading: false,
      doneLoading: false,
      showDelete : false,
      deleteLoading: false,
      showAddressModal: false
    };
  },
  computed: {
    preset() {
      return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
    },

    getPilotAddress(){
      if (this.pilotData.address.houseNumber) {
        return this.toSentenceCase(this.pilotData.address.houseNumber) + " "
            +this.toSentenceCase(this.pilotData.address.streetName) +
            ", " + this.toSentenceCase(this.pilotData.address.city) + " "
            +this.toSentenceCase(this.pilotData.address.state)
            + " "+this.toSentenceCase(this.pilotData.address.country)
      }
      else {
        return ""
      }
    },
  },
  methods: {

    handleShowAddressModal(){
      this.showAddressModal = true
    },

    closeHandleModal(){
      this.showAddressModal = !this.showAddressModal
    },

    handleAddress(address){
      this.pilotData.address = address
    },

    toSentenceCase(text) {
      text = text.toLowerCase()
      return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
    },
    getNumber(number) {
      this.pilotData.phoneNumber = number;
    },
    handleShowDelete(){
      this.showDelete = !this.showDelete
    },
    backToPilots() {
      this.$store.dispatch(
        "pilotComponent/setCurrentComponent",
        "allPilots"
      );
      sessionStorage.setItem("pilotComponent", "allPilots");
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.logoImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.recoveredImg = "";
          this.image = fileReader.result;
          this.logoImage = files.name;
          let newData = {
            file: this.image,
            upload_preset: this.preset
          };
          const response = await uploadToCloudinary(newData);
          this.pilotData.profileImage = response.data.url;
          this.logoImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
      }
    },

    async getCountries() {
      this.loading = true
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();
        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err);
        this.loading = false
        this.getCountries = false;
      }
    },
    async getState() {
      try {
        this.getStatesLoading = true;
        let data = {};
        if (this.pilotData.address.country.name) {
          data.country = this.pilotData.address.country.name;
        } else {
          data.country = this.pilotData.address.country;
        }
        const response = await getAllStatesByCountry(data);
        this.states = response.data.data.states;
        if (this.pilotData.address.country.name === "Nigeria" || this.pilotData.address.country === "Nigeria") {
          this.states.push({name: 'Rivers State'})
        }
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCity() {
      if (this.pilotData.address.country.name === "Nigeria" || this.pilotData.address.country === "Nigeria") {
        this.cities = Lga[this.pilotData.address.state.name || this.pilotData.address.state]
      } else {
        try {
          this.getCitiesLoading = true;
          let data = {};
          if (this.pilotData.address.country.name){
            data.country=this.pilotData.address.country.name
            data.state= this.pilotData.address.state.name
          }
          else {
            data.country=this.pilotData.address.country
            data.state= this.pilotData.address.state
          }
          const response = await getAllCitiesInAState(data);
          this.cities = response.data.data;
          this.getCitiesLoading = false;
        } catch (err) {
          console.log(err.response);
          this.cities = [];
          this.getCitiesLoading = false;
        }
      }
    },
   async getPilotById(){
    let pilotId =  sessionStorage.getItem("pilotId")
      if (pilotId) {
        let data = {}
        data.pilotId = pilotId
        data.transportCompanyId = this.companyData.id
      await getPilotById(data).then(res => {
        this.pilotData = res.data
        this.loading = false
        }).catch(error => {
          this.$displaySnackbar({
            message: error.response.data.details[0],
            success: false,
          });
        this.loading = false
        })
      }
    },
   async updatePilot(){
      if (this.validatePhone(this.pilotData.phoneNumber) ){
        this.doneLoading = true
        let  data = {}
        data = this.pilotData
        data.terminalId = data.terminalAssignedId
        data.pilotId = data.id
        await updatePilotById(data).then(() =>{
          this.$displaySnackbar({
            message: "Pilot updated successfully!",
            success: true,
          });
          this.doneLoading = false
          this.backToPilots()
        }).catch(error => {
          this.doneLoading = false
          this.replace234()
          this.$displaySnackbar({
            message: error.response.data.details[0],
            success: false,
          });

        }).finally(()=> this.doneLoading = false)
      }
   },
    deletePilot(){
      this.deleteLoading = true
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.pilotId = this.pilotData.id
      deletePilotById(data).then(() =>{
        this.$displaySnackbar({
          message: "Pilot deleted successfully!",
          success: true,
        });
        this.deleteLoading = false
        this.backToPilots()
      }).catch(error => {
        this.deleteLoading = false
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
      })
    },
   async getTerminals(){
      this.loading = true
     await getTransportCompanyTerminals(this.companyData.id).then(res =>{
        this.terminals = res.data
       this.loading = false
      }).catch(error => {
            this.$displaySnackbar({
              message: error.response.data.details[0],
              success: false,
            });
            this.loading = false
          })
    },
    validatePhone(phoneNumber){
      if(phoneNumber.length > 6) {
        return true;
      }
      else {
        this.$displaySnackbar({
          message: "Phone number not valid e.g 2348000000000",
          success: false,
        });
        return false;
      }

    },
    validEmail(email){
      let valid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (email.match(valid)){
        return true
      }
      else {
        this.$displaySnackbar({
          message: "Email not valid e.g example@company.com",
          success: false,
        });
        return false;
      }
    },
    replace234(){
      if (this.pilotData.phoneNumber){
        this.pilotData.phoneNumber = this.pilotData.phoneNumber.replace("234","")
      }
    }
  },
  mounted() {
    this.getCountries();
  },
  async created() {
    this.loading = true
    let companyData = JSON.parse(sessionStorage.getItem("companyData"))
    if (companyData){
      this.companyData = companyData
    }
    await this.getPilotById()
    this.replace234()
    await this.getTerminals()
    if (this.pilotData.address.country){
      await this.getState()
    }
    if (this.pilotData.address.state){
      await this.getCity()
    }

  }
};
</script>

<style lang="scss" scoped>
.upload-div .upload-div-text {
  font-family: "Inter",sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 84px;
  width: 84px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-logo-input .button-logo-input-img {
  width: 25px;
  height: 25px;
}
.action-btn-div {
  font-family: "Inter", sans-serif!important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  .label {
    font-family: "Inter", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
