<template>
  <v-col sm="12">
    <loader v-if="loading" :color="companyColor" :loading="loading" />
    <v-col sm="12" class="tw-p-0 tw-flex-col" v-else>
      <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-between">
        <icon-and-text
          :icon="addpilot"
          title="New Pilots"
          desc="Add a Pilot"
          :width="$vuetify.breakpoint.mdAndUp ?  '25%' : '100%'"
          @clickAction="addPilot"
        />
        <icon-and-text
          :icon="allpilot"
          :title="pilots.length ? pilots.length.toString() : 'Nil'"
          desc="All Pilot"
          :width="$vuetify.breakpoint.mdAndUp ?  '25%' : '100%'"
        />
        <image-and-text-two
          :showImage="false"
          :title="mostRecent ? mostRecent.terminalAssignedName : 'Nil'"
          labelOne="Email: "
          labelTwo="Mobile: "
          :valueOne="mostRecent ? mostRecent.email : 'Nil'"
          :valueTwo="mostRecent ? '+' + mostRecent.phoneNumber : 'Nil'"
          :width="$vuetify.breakpoint.mdAndUp ?  '48%' : '100%'"
          :height="$vuetify.breakpoint.mdAndUp ? '100px' : 'auto'"
        />
      </div>

      <div
        class="
          tw-flex tw-w-full tw-flex-row tw-justify-between tw-py-5 tw-mt-8
          table-container-div
          tw-px-1 tw-pl-8
        "
        style="background: white"
      >
        <div class="table-container tw-pr-8">
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <p class="table-header" :style="{ color: companyColor }">
              All Pilots
            </p>
            <div class="tw-flex tw-flex-row tw-justify-center tw-items-center">
              <div
                class="mr-2"
                style="
                  border-radius: 10px;
                  border: 1px solid rgba(79, 79, 79, 0.08);
                "
              >
                <v-text-field
                  placeholder="Search"
                  class="p-0"
                  solo
                  flat
                  hide-details
                  v-model="search"
                >
                  <template #prepend>
                    <v-icon :color="companyColor" class="ml-5">
                      mdi-magnify
                    </v-icon>
                  </template>
                  <template #append>
                    <v-icon :color="companyColor"> mdi-filter-variant </v-icon>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            v-model="selectedRow"
            :mobile-breakpoint="0"
            :search="search"
            item-key="id"
            :single-select="false"
            height="available"
            :hide-default-footer="pilots.length <= 10"
            :items="pilots"
            :no-data-text="not_found"
            class="mt-4 mx-1 table"
            :checkbox-color="companyColor"
            @click:row="viewSingle"
          >
            <template #header.sno>
              <span class="status">S/N</span>
            </template>
            <template v-slot:[`item.sno`]="{ item }">
              <span class="td-style">{{ item.sno }}</span>
            </template>
            <template #header.profileImage>
              <span class="status">Image</span>
            </template>
            <template v-slot:[`item.profileImage`]="{ item }">
              <img
                :src="item.profileImage"
                alt="terminal"
                v-if="item.profileImage"
              />
              <div
                class="tw-flex tw-justify-center tw-items-center no-image"
                v-else
                :style="{ backgroundColor: companyColor }"
              >
                <p class="tw-text-center tw-p-0 tw-m-0 tw-mb-o">
                  {{ item.firstname[0] }}
                </p>
              </div>
            </template>
            <template #header.firstname>
              <span class="status">First Name</span>
            </template>
            <template v-slot:[`item.firstname`]="{ item }">
              <span class="td-style text-capitalize">{{ item.firstname }}</span>
            </template>

            <template #header.lastname>
              <span class="status text-capitalize">Last Name</span>
            </template>
            <template v-slot:[`item.lastname`]="{ item }">
              <span class="td-style text-capitalize">{{ item.lastname }}</span>
            </template>
            <template #header.email>
              <span class="status">Email</span>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <span class="td-style">{{ item.email }}</span>
            </template>
            <template #header.mobile>
              <span class="status">Mobile</span>
            </template>
            <template v-slot:[`item.mobile`]="{ item }">
              <span class="td-style">{{ item.phoneNumber }}</span>
            </template>
            <template #header.country>
              <span class="status ">Country</span>
            </template>
            <template v-slot:[`item.country`]="{ item }">
              <span class="td-style text-capitalize" v-if="item.address">{{ item.address.country }}</span>
            </template>
            <template #header.terminal>
              <span class="status">Terminal</span>
            </template>
            <template v-slot:[`item.terminal`]=" { item }">
              <span class="td-style text-capitalize">{{ item.terminalAssigned }}</span>
            </template>
            <template #header.actions>
              <span class="status"></span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-center">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="companyColor">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="" @click="handleShowAssign(item)">
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer menu-text"
                    >
                      <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2 icon-size"
                      />
                      {{
                        item.fleetAssigned ? "Re-Assign Vehicle" : "Assign Vehicle"
                      }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="" @click="selectEditTab(item.id)">
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex tw-cursor-pointer menu-text"
                    >
                      <img
                        src="@/assets/icons/edit-icon.svg"
                        alt=""
                        class="tw-mr-2 icon-size"
                      />
                      Edit
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleShowDelete(item.id)">
                    <v-list-item-title
                      style="color: black"
                      class="tw-flex-row tw-flex menu-text"
                    >
                      <img
                        src="@/assets/icons/del-icon.svg"
                        alt=""
                        class="tw-mr-2 icon-size"
                      />
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-col>
    <assign-modal
      :dialog="showAssign"
      title="Assign vehicle to pilot"
      :pilot-id="pilotId"
      :pilot-terminal-id="pilotTerminalId"
      @close="handleShowAssign"
      assign-type="pilot"
    />
    <delete-modal
      @close="handleShowDelete"
      description="Are you sure you want to delete this pilot"
      @deleteAction="deletePilot"
      :done-loading="deleteLoading"
      :dialog="showDelete"
    />
  </v-col>
</template>

<script>
import IconAndText from "../IconAndText";
import ImageAndTextTwo from "../ImageAndTextTwo";
import addpilot from "@/assets/icons/addpilot.png";
import allpilot from "@/assets/icons/allpilots.png";
import locationImg from "@/assets/model.jpg";
import {
  deletePilotById,
  getAllCompanyPilot,
} from "../../../services/api/APIService";
import Loader from "../../reuseables/Loader";
import AssignModal from "../../reuseables/AssignModal";
import DeleteModal from "../../reuseables/DeleteModal";
import dayjs from "dayjs";

export default {
  name: "AllPilotDashboard",
  components: {
    DeleteModal,
    AssignModal,
    Loader,
    IconAndText,
    ImageAndTextTwo,
  },
  data() {
    return {
      addpilot,
      allpilot,
      locationImg,
      pilots: [],
      mostRecent: {},
      companyColor: "#004AAD",
      companyData: {},
      loading: false,
      search: "",
      headers: [
        { text: "", value: "sno" },
        { text: "", value: "profileImage" },
        { text: "", value: "firstname" },
        { text: "", value: "lastname" },
        { text: "", value: "email" },
        { text: "", value: "mobile" },
        { text: "", value: "country" },
        { text: "", value: "terminal" },
        { text: "", value: "actions", align: "end" },
      ],
      selectedRow: [],
      not_found: "Pilots will display here",
      showAssign: false,
      pilotId: null,
      pilotTerminalId : null,
      showDelete: false,
      deleteLoading: false,
    };
  },
  methods: {
    handleShowAssign(pilot) {
       if (pilot) {
           this.pilotId = pilot.id;
           this.pilotTerminalId = pilot.terminalAssignedId
       }
      this.showAssign = !this.showAssign;
    },
    handleShowDelete(id) {
      this.pilotId = id;
      this.showDelete = !this.showDelete;
    },
    selectEditTab(id) {
      this.$store.dispatch("pilotComponent/setCurrentComponent", "editPilot");
      sessionStorage.setItem("pilotComponent", "editPilot");
      sessionStorage.setItem("pilotId", id);
    },
    viewSingle(pilot) {
      this.$store.dispatch("pilotComponent/setCurrentComponent", "singlePilot");
      sessionStorage.setItem("pilotComponent", "singlePilot");
      sessionStorage.setItem("pilotId", pilot.id);
    },
    addPilot() {
      this.$store.dispatch("pilotComponent/setCurrentComponent", "addPilot");
      sessionStorage.setItem("pilotComponent", "addPilot");
    },
    async getAllPilots() {
      this.loading = true;
      try {
        const response = await getAllCompanyPilot(this.companyData.id);
        this.pilots = response.data;
        this.mostRecent = this.pilots[this.pilots.length - 1];
        this.pilots.sort(function (a, b) {
          return dayjs(b.createdAt).toDate() - dayjs(a.createdAt).toDate();
        });
        this.pilots.forEach((pilot, index) => (pilot.sno = index + 1));
        this.loading = false;
      } catch (err) {
        console.log(err.response);
        this.loading = false;
      }
    },
    deletePilot() {
      this.deleteLoading = true;
      let data = {};
      data.transportCompanyId = this.companyData.id;
      data.pilotId = this.pilotId;
      deletePilotById(data)
        .then(() => {
          this.$displaySnackbar({
            message: "Pilot deleted successfully!",
            success: true,
          });
          this.deleteLoading = false;
          this.showDelete = !this.showDelete;
          this.getAllPilots();
        })
        .catch((error) => {
          this.deleteLoading = false;
          this.$displaySnackbar({
            message: error.response.data.details[0],
            success: false,
          });
        });
    },
  },
  async created() {
    this.loading = true;
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getAllPilots();
  },
};
</script>

<style lang="scss" scoped>
.main-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: available;
  padding: 0 !important;
}
.table-header {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}

.no-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;

  p {
    font-family: "Inter", serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    margin-bottom: 0 !important;
    color: rgba(255, 255, 255, 1);
  }
}

img {
  width: 32px;
  height: 32px;
  border-radius: 5px;
}

::v-deep .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0;
}

.v-data-table__wrapper > table {
  margin-top: 55px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:first-child,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  padding-left: 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.status {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  color: #4f4f4f;
  padding: 0 0 0 5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.td-style {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  padding: 0 0 0 5px;
}
.menu-text {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0;
}

.icon-size {
  height: 16px;
  width: auto;
}

::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar {
  background: transparent !important;
  width: auto;
  height: 2px;
}
::v-deep .v-data-table--fixed-height .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 1px !important;
  border-radius: 10px;
}
</style>
