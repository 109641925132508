<template>
  <v-col sm="12">
    <loader :color="companyData.companyColor" v-if="loading" />

    <v-col sm="12" class="main-body" v-else>
      <delete-modal
          :dialog="dialog"
          @close="closeModal"
          description="Are You sure you want to delete?"
          @deleteAction="deletePilot"
          :doneLoading="deleteLoading"
      />

      <v-col sm="12" class=" tw-flex tw-justify-between tw-items-center">
        <button
            class="tw-flex tw-items-center tw-mt-6 overview-btn"
            @click="backToPilots"
        >
          <icons name="arrow-back" :color="companyData.companyColor" />
          <span class="tw-ml-4" :style="{ color: companyData.companyColor }"
          >All Pilots</span
          >
        </button>
        <div class="tw-flex tw-items-center">
          <button @click="selectEditTab">
            <icons name="edit" :color="companyData.companyColor" />
          </button>
          <button class="tw-pl-5" @click="closeModal" v-if="!pilotData.fleetAssigned">
            <icons name="trash" :color="companyData.companyColor"  />
          </button>
        </div>
      </v-col>
      <v-col sm="12" class="tw-bg-white tw-mt-7 tw-rounded-xl tw-py-16  lg:tw-px-16">
        <div class="lg:tw-px-12 tw-w-full tw-flex tw-flex-col-reverse lg:tw-flex-row lg:tw-justify-between  lg:tw-items-center">
          <v-col sm="12" lg="5" class="img-div tw-m-0 tw-p-0 ">
            <img
                :src="pilotData.profileImage"
                v-if="pilotData.profileImage"
                :alt="pilotData.firstname"
            />
            <avatar v-else :avatar="pilotData.firstname.charAt(0)" :color="companyData.companyColor"/>
          </v-col>
          <v-divider v-if="$vuetify.breakpoint.smAndDown" class="tw-mb-5"/>
          <v-col sm="12" lg="5" class="tw-flex tw-flex-col" v-if="pilotData.fleetAssigned">
            <img class="fleet-img" :src="pilotData.fleetAssigned.vehicleImage" alt="fleet">
            <div class="tw-flex tw-flex-row tw-items-center">
            <v-col class="label-key tw-m-0 tw-py-0 tw-pl-0 tw-pr-2">Plate Number</v-col>
            <v-col  class="label-value tw-m-0 tw-p-0 text-uppercase">{{pilotData.fleetAssigned.vehicleRegNumber }}</v-col>
            </div>
            <div class="tw-flex tw-flex-row tw-items-center">
              <v-col class="label-key tw-m-0 tw-py-0 tw-pl-0 tw-pr-2">Tag Number</v-col>
              <v-col  class="label-value tw-m-0 tw-p-0 text-uppercase">{{pilotData.fleetAssigned.vehicleTagNumber }}</v-col>
            </div>
          </v-col>
        </div>

        <v-row class="tw-mt-6 tw-hidden lg:tw-flex tw-flex-col lg:tw-flex-row lg:tw-px-12 lg:tw-justify-between">
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">First Name</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{pilotData.firstname }}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Street No.</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 tw-p-0 text-capitalize" v-if="pilotData.address">{{pilotData.address.houseNumber }}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Last Name</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{pilotData.lastname }}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Street Name</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 tw-p-0 text-capitalize" v-if="pilotData.address">{{pilotData.address.streetName }}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Email</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 tw-p-0 text-lowercase">{{pilotData.email }}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Town/City</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 tw-p-0 text-capitalize" v-if="pilotData.address">{{pilotData.address.city }}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Phone Number</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{pilotData.phoneNumber }}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">State</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 tw-p-0 text-capitalize" v-if="pilotData.address">{{pilotData.address.state}}</v-col>
            </div>
          </v-col>
          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Terminal</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 tw-p-0 text-capitalize">{{pilotData.terminalAssigned }}</v-col>
            </div>
          </v-col>

          <v-col sm="12" lg="5">
            <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
              <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Country</v-col>
              <v-col sm="12" lg="6" class="label-value tw-m-0 tw-p-0 text-capitalize" v-if="pilotData.address">{{pilotData.address.country }}</v-col>
            </div>
          </v-col>
        </v-row>
        <div class="tw-mt-6 tw-flex lg:tw-hidden tw-flex-col">
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">First Name</v-col>
            <v-col sm="12" lg="6" class="label-value tw-m-0 tw-py-3 tw-px-0 text-capitalize">{{pilotData.firstname }}</v-col>
          </div>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Last Name</v-col>
            <v-col sm="12" lg="6" class="label-value tw-m-0 tw-py-3 tw-px-0 text-capitalize">{{pilotData.lastname }}</v-col>
          </div>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Email</v-col>
            <v-col sm="12" lg="6" class="label-value tw-m-0 tw-py-3 tw-px-0 text-capitalize">{{pilotData.email }}</v-col>
          </div>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Phone Number</v-col>
            <v-col sm="12" lg="6" class="label-value tw-m-0 tw-py-3 tw-px-0 text-capitalize">{{pilotData.phoneNumber }}</v-col>
          </div>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Terminal</v-col>
            <v-col sm="12" lg="6" class="label-value tw-m-0 tw-py-3  tw-px-0 text-capitalize">{{pilotData.terminalAssigned }}</v-col>
          </div>
          <v-divider class="tw-my-5"/>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Street No.</v-col>
            <v-col sm="12" lg="6" class="label-value tw-m-0 tw-py-3 tw-px-0 text-capitalize" v-if="pilotData.address">{{pilotData.address.houseNumber }}</v-col>
          </div>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Street Name</v-col>
            <v-col sm="12" lg="6" class="label-value tw-m-0 tw-py-3 tw-px-0 text-capitalize" v-if="pilotData.address">{{pilotData.address.streetName }}</v-col>
          </div>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">City</v-col>
            <v-col sm="12" lg="6" class="label-value tw-m-0 tw-py-3 tw-px-0 text-capitalize" v-if="pilotData.address">{{pilotData.address.city }}</v-col>
          </div>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">State</v-col>
            <v-col sm="12" lg="6" class="label-value tw-m-0 tw-py-3 tw-px-0 text-capitalize" v-if="pilotData.address">{{pilotData.address.state }}</v-col>
          </div>
          <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
            <v-col sm="12" lg="6" class="label-key tw-m-0 tw-p-0">Country</v-col>
            <v-col sm="12" lg="6" class="label-value tw-m-0 tw-py-3 tw-px-0 text-capitalize" v-if="pilotData.address">{{pilotData.address.country }}</v-col>
          </div>
        </div>
      </v-col>
    </v-col>
  </v-col>
</template>

<script>
import Icons from "../../reuseables/Icons";
import Loader from "../../reuseables/Loader";
import DeleteModal from "../../reuseables/DeleteModal";
import {deletePilotById, getPilotById} from "@/services/api/APIService";
import Avatar from "../../reuseables/Avatar";
export default {
  name: "SinglePilot",
  components: {Avatar, DeleteModal, Loader, Icons},
  data() {
    return {
      companyData:{},
      pilotData:{
        address:{}
      },
      loading: false,
      dialog : false,
      deleteLoading : false,
    };
  },
  methods: {
    closeModal(){
      this.dialog = !this.dialog
    },
    backToPilots() {
      this.$store.dispatch(
        "pilotComponent/setCurrentComponent",
        "allPilots"
      );
      sessionStorage.setItem("pilotComponent", "allPilots");
    },
    selectEditTab() {
      this.$store.dispatch(
        "pilotComponent/setCurrentComponent",
        "editPilot"
      );
      sessionStorage.setItem("pilotComponent", "editPilot");
      sessionStorage.setItem("pilotId",this.pilotData.id)
    },
    deletePilot(){
      this.deleteLoading = true
      let data = {}
      data.transportCompanyId = this.companyData.id
      data.pilotId = this.pilotData.id
      deletePilotById(data).then(() =>{
        this.$displaySnackbar({
          message: "Pilot deleted successfully!",
          success: true,
        });
        this.deleteLoading = false
        this.backToPilots()
      }).catch(error => {
        this.deleteLoading = false
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
      })
    },
    async getPilotById(){
      this.loading = true
      let pilotId =  sessionStorage.getItem("pilotId")
      if (pilotId) {
        let data = {}
        data.pilotId = pilotId
        data.transportCompanyId = this.companyData.id
        await getPilotById(data).then(res => {
          this.pilotData = res.data
          this.loading = false
        }).catch(error => {
          this.$displaySnackbar({
            message: error.response.data.details[0],
            success: false,
          });
          this.loading = false
        })
      }
    },
  },
 async created() {
    let companyData = JSON.parse(sessionStorage.getItem('companyData'))
    if (companyData){
      this.companyData = companyData
    }
    await this.getPilotById()
  }
};
</script>

<style lang="scss" scoped>
.view-link {
  font-family: "Inter",sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: rgba(0, 74, 173, 1);
}

.overview-btn {
  border: none !important;
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}

.card-div {
  img {
    width: 88px;
    height: 88px;
    border-radius: 50%;
  }
  .header {
    font-family: "Inter",sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
  }

  .header-id {
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }
}

.img-div {
  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  .header {
    font-family: "Inter",sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .header-id {
    font-family: "Inter",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
}


.table-container-div {
  box-shadow: 0px 4px 40px 0px #4f4f4f05;
  border-radius: 10px;
  .table-container {
    width: 100%;
    .table-container-header {
      font-family: "Inter",sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #004aad;
    }
  }
}
.label-key {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.label-value {
  font-family: "Inter",sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.fleet-img{
  height: auto;
  width: 300px;
}
</style>
