<template>
  <!-- <router-view ></router-view> -->
  <Component v-bind:is="displayTerminalComponent()" />
</template>

<script>
import TerminalDashboard from "../terminal/ViewTerminalDashboard.vue";
import AllPilots from "./AllPilotDashboard";
import SinglePilot from "./SinglePilot";
import EditPilot from "./EditPilot";
import AddPilot from "./AddPilot";
import { mapGetters } from "vuex";

export default {
  name: "ViewPilotDashboardRoute",
  computed: {
    ...mapGetters("pilotComponent", ["currentComponent"])
  },
  methods: {
    displayTerminalComponent() {
      let component = this.currentComponent;
      if (component === "terminalDashboard") {
        return TerminalDashboard;
      } else if (component === "allPilots") {
        return AllPilots;
      } else if (component === "singlePilot") {
        return SinglePilot;
      } else if (component === "editPilot") {
        return EditPilot;
      } else if (component === "addPilot") {
        return AddPilot;
      }
    }
  },
  created() {
    let component = sessionStorage.getItem("pilotComponent");

    if (!component) {
      sessionStorage.setItem("pilotComponent", "allPilots");
      this.$store.dispatch("pilotComponent/setCurrentComponent", "allPilots");
      this.displayTerminalComponent()

    } else {
      this.$store.dispatch("pilotComponent/setCurrentComponent", component);
      this.displayTerminalComponent()
    }
  }
};
</script>

<style lang="scss" scoped></style>
