<template>
  <v-col sm="12">
    <button
      class="tw-flex tw-items-center tw-mt-6 overview-btn"
      @click="backToAllPilots"
      :style="{ color: companyColor }"
    >
      <icons name="arrow-back" :color="companyColor" />
      <span class="tw-ml-4">All Pilots</span>
    </button>

    <v-col
      sm="12"
      class="
        tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full
      "
    >
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(addPilot)">
          <div
            class="
              tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10
            "
          >
            <p class="edit-header" :style="{ color: companyColor }">
              New Pilot
            </p>
          </div>
          <v-row class="upload-div tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Upload Photo</p>
                <p class="label-info">Choose an image as a profile</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <button
                class="button-logo-input"
                type="button"
                @click="onLogoFile()"
                :class="image ? 'border-0' : ''"
              >
                <v-progress-circular
                  :size="100"
                  :width="3"
                  :color="companyColor"
                  indeterminate
                  style="position: absolute"
                  v-if="logoImageLoading"
                ></v-progress-circular>

                <img
                  :src="image"
                  alt=""
                  class="button-logo-img"
                  v-if="logoImage"
                />
                <img
                  src="../../../assets/camera.png"
                  alt=""
                  class="button-logo-input-img"
                  v-else
                />
              </button>

              <input
                type="file"
                ref="logoImage"
                accept="image/*"
                style="display: none"
                @change="onLogoImagePicked"
              />
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">First Name</p>
                <p class="label-info">Type the First Name of the Pilot</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="First Name"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-text-field
                    placeholder="Mason"
                    solo
                    hide-details
                    type="text"
                    required
                    autofocus
                    v-model="pilotData.firstname"
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Last Name</p>
                <p class="label-info">Type the Last Name of the Pilot</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Last Name"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-text-field
                    placeholder="Ryan"
                    solo
                    hide-details
                    type="text"
                    required
                    v-model="pilotData.lastname"
                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Email (Optional)</p>
                <p class="label-info">Type the Email Address of the Pilot</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Pilot's Email"

                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-text-field
                    placeholder="ryan@gmail.com"
                    solo
                    hide-details
                    type="email"
                    v-model="pilotData.email"

                  ></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Mobile</p>
                <p class="label-info">Type the Mobile Number of the Pilot</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <phone-number
                :phone-number-exist="pilotData.phoneNumber"
                @getNumber="getNumber" :required="false"
              />
            </v-col>
          </v-row>
          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Address</p>
                <p class="label-info">
                  Enter the address in which the Pilot is located.
                </p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Address"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="tw-cursor-pointer" :class="classes" @click="handleShowAddressModal">
                  <v-text-field
                    placeholder="371,Borno Way, Alagomeji Yaba, Lagos"
                    solo
                    hide-details
                    type="text"
                    required
                    v-model="getPilotAddress"
                  >
                  </v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>



          <v-row class="tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row">
            <v-col sm="12" lg="4" class="">
              <div class="form-label-div">
                <p class="label">Terminal</p>
                <p class="label-info">Choose the Terminal for the Pilot.</p>
              </div>
            </v-col>
            <v-col sm="12" lg="8" class="">
              <ValidationProvider
                name="Country"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
                  <v-combobox
                    :items="terminals"
                    placeholder="Choose Terminal"
                    append-icon="mdi-chevron-down"
                    item-text="terminalName"
                    item-value="id"
                    v-model="pilotData.terminalId"
                    solo
                    hide-details
                  >
                    <template v-slot:item="{ item }">
                      <label
                        >{{ item.terminalName }} {{ item.address.city }},
                        {{ item.address.state }}</label
                      >
                    </template>
                  </v-combobox>
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </v-col>
          </v-row>
          <div
            class="
              tw-flex tw-w-full tw-justify-end tw-items-center
              action-btn-div
              tw-mt-10
            "
          >
            <v-btn
              @click="backToAllPilots"
              text
              class="first-btn"
              :style="{
                color: companyColor,
                border: '1px solid' + companyColor,
              }"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              class="second-btn"
              type="submit"
              :style="{ background: companyColor }"
              :loading="doneLoading"
            >
              Done
            </v-btn>
          </div>
        </form>
      </validation-observer>
    </v-col>
    <modal section="custom-address" :address="pilotData.address"
           :dialog="showAddressModal" @handleAddress="handleAddress"  @close="closeHandleModal"/>
  </v-col>
</template>

<script>
import {
  getAllCountries,
  getAllStatesByCountry,
  getAllCitiesInAState,
  uploadToCloudinary,
  getTransportCompanyTerminals,
  getAllCompanyFleets,
  addPilot,
} from "../../../services/api/APIService";
import Icons from "../../reuseables/Icons";
import Lga from "../../../assets/lga";
import PhoneNumber from "../../reuseables/PhoneNumber";
import Modal from "@/components/reuseables/Modal.vue";
export default {
  name: "AddBooking",
  components: {Modal, PhoneNumber, Icons },
  data() {
    return {
      country: "",
      state: "",
      city: "",
      streetNumber: "",
      streetName: "",
      getCountriesLoading: false,
      getStatesLoading: false,
      getCitiesLoading: false,
      countries: [],
      states: [],
      cities: [],
      logoImage: "",
      logoImageUrl: "",
      logoImageLoading: false,
      image: "",
      buses: [{ name: "IKY 367 AE" }, { name: "IKY 847 GB" }],
      companyColor: "",
      companyData: {},
      terminals: [],
      pilotData: {
        firstname: "",
        lastname: "",
        phoneNumber: "",
        email: "",
        profileImage: "",
        address: {
          country: "",
          state: "",
          city: "",
          streetName: "",
          houseNumber: "",
        },
        terminalId: "",
        fleetId: "",
      },
      showAddressModal: false,
      doneLoading: false,
    };
  },
  props: {},
  computed: {
    preset() {
      return process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET;
    },

    getPilotAddress(){
      if (this.pilotData.address.houseNumber) {
        return this.toSentenceCase(this.pilotData.address.houseNumber) + " "
            +this.toSentenceCase(this.pilotData.address.streetName) +
            ", " + this.toSentenceCase(this.pilotData.address.city) + " "
            +this.toSentenceCase(this.pilotData.address.state)
            + " "+this.toSentenceCase(this.pilotData.address.country)
      }
      else {
        return ""
      }
    },
  },
  methods: {

    handleShowAddressModal(){
      this.showAddressModal = true
    },

    closeHandleModal(){
      this.showAddressModal = !this.showAddressModal
    },

    handleAddress(address){
      this.pilotData.address = address
    },

    toSentenceCase(text) {
      text = text.toLowerCase()
      return text.replace(text.charAt(0), text.charAt(0).toUpperCase())
    },

    backToAllPilots() {
      this.$store.dispatch("pilotComponent/setCurrentComponent", "allPilots");
      sessionStorage.setItem("pilotComponent", "allPilots");
    },
    onLogoFile() {
      this.$refs.logoImage.click();
    },
    async onLogoImagePicked(event) {
      const files = event.target.files[0];
      try {
        this.logoImageLoading = true;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
          this.recoveredImg = "";
          this.image = fileReader.result;
          this.logoImage = files.name;
          let newData = {
            file: this.image,
            upload_preset: this.preset,
          };
          const response = await uploadToCloudinary(newData);
          console.log(response);
          this.logoImageUrl = response.data.url;
          this.logoImageLoading = false;
        });
        fileReader.readAsDataURL(files);
      } catch (err) {
        console.log(err.response);
      }
    },

    async getCountries() {
      try {
        this.getCountriesLoading = true;
        const response = await getAllCountries();
        console.log(response);
        this.countries = response.data.data;
        this.getCountriesLoading = false;
      } catch (err) {
        console.log(err);
        this.getCountries = false;
      }
    },
    async getState() {
      try {
        this.getStatesLoading = true;
        let data = {
          country: this.pilotData.address.country.name,
        };
        const response = await getAllStatesByCountry(data);
        this.states = response.data.data.states;
        if (this.pilotData.address.country.name.toLowerCase() === "nigeria") {
          this.states.push({ name: "Rivers State" });
        }
        this.states.forEach(state => state.name === 'Lagos State' ? state.name = 'Lagos' : state.name)
        this.getStatesLoading = false;
      } catch (err) {
        console.log(err.response);
      }
    },
    async getCity() {
      if (this.pilotData.address.country.name === "Nigeria") {
        this.cities = Lga[this.pilotData.address.state.name];
      } else {
        try {
          this.getCitiesLoading = true;
          let data = {
            country: this.pilotData.address.country,
            state: this.pilotData.address.state,
          };
          if (this.pilotData.address.country.name) {
            data.country = this.pilotData.address.country.name;
          }
          if (this.pilotData.address.state.name) {
            data.state = this.pilotData.address.state.name;
          }
          const response = await getAllCitiesInAState(data);
          console.log(response);
          this.cities = response.data.data;
          this.getCitiesLoading = false;
        } catch (err) {
          console.log(err.response);
          this.cities = [];
          this.getCitiesLoading = false;
        }
      }
    },
    async getAllTerminals() {
      try {
        const response = await getTransportCompanyTerminals(
          this.companyData.id
        );
        this.terminals = response.data;
      } catch (error) {
        console.log(error.response);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
      }
    },
    async addPilot() {
      this.doneLoading = true;
      let data = this.pilotData;
      if (this.pilotData.address.country.name) {
        data.address.country = this.pilotData.address.country.name;
      }
      if (this.pilotData.address.state.name) {
        data.address.state = this.pilotData.address.state.name;
      }
      if (this.pilotData.address.city.name) {
        data.address.city = this.pilotData.address.city.name;
      }
      if (data.terminalId.id) {
        data.terminalId = data.terminalId.id;
      }
      data.profileImage = this.logoImageUrl;
      data.transportCompanyId = this.companyData.id;
      if (
         this.pilotData.phoneNumber && this.validatePhone(this.pilotData.phoneNumber)
      ) {
          if (this.pilotData.email && this.validEmail(this.pilotData.email)){
              await addPilot(data)
                  .then(() => {
                      this.backToAllPilots();
                      this.doneLoading = false;
                  })
                  .catch((err) => {
                      this.$displaySnackbar({
                          message: err.response.data.message,
                          success: false,
                      });
                      this.doneLoading = false;
                  }).finally(() => this.doneLoading = false);
          }
          else {
              await addPilot(data)
                  .then(() => {
                      this.backToAllPilots();
                      this.doneLoading = false;
                  })
                  .catch((err) => {
                      this.$displaySnackbar({
                          message: err.response.data.message,
                          success: false,
                      });
                      this.doneLoading = false;
                  }).finally(()=> this.doneLoading = false);
          }

      } else {
          await addPilot(data)
              .then(() => {
                  this.backToAllPilots();
                  this.doneLoading = false;
              })
              .catch((err) => {
                  this.$displaySnackbar({
                      message: err.response.data.details[0],
                      success: false,
                  });
                  this.doneLoading = false;
              });
        this.doneLoading = false;
      }
    },
    getNumber(number) {
      this.pilotData.phoneNumber = number;
    },
    async getAllFleets() {
      try {
        const response = await getAllCompanyFleets(this.companyData.id);
        this.buses = response.data;
      } catch (error) {
        console.log(error.response);
        this.$displaySnackbar({
          message: error.response.data.details[0],
          success: false,
        });
      }
    },
    validatePhone(phoneNumber) {
      // let num = /^\(?([0-9]{3})\)?([0-9]{3})?([0-9]{3})?([0-9]{4})$/;
      if (phoneNumber.length > 6) {
        return true;
      } else {
        this.$displaySnackbar({
          message: "Phone number not valid e.g 2348000000000",
          success: false,
        });
        return false;
      }
    },
    validEmail(email) {
      let valid =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.match(valid)) {
        return true;
      } else {
        this.$displaySnackbar({
          message: "Email not valid e.g example@company.com",
          success: false,
        });
        return false;
      }
    },
  },
  mounted() {
    this.getCountries();
  },
  async created() {
    let companyData = JSON.parse(sessionStorage.getItem("companyData"));
    this.companyData = companyData;
    if (companyData) {
      this.companyColor = companyData.companyColor;
    }
    await this.getAllTerminals();
    await this.getAllFleets();
  },
};
</script>

<style lang="scss" scoped>
.upload-div .upload-div-text {
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.button-logo-input {
  display: flex;
  position: relative;
  border-radius: 100%;
  align-items: center;
  height: 80px;
  width: 80px;
  justify-content: center;
  border: 1px solid rgba(189, 189, 189, 0.3);
}
.button-logo-input .button-logo-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.button-logo-input .button-logo-input-img {
  width: 24.19px;
  height: 18.72px;
}
.action-btn-div {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;

  .first-btn {
    width: 120px;
    height: 32px;
    color: #004aad;
    border: 1px solid #004aad;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .second-btn {
    width: 115px;
    height: 32px;
    background: #004aac;
    border-radius: 6px;
    color: white;
    margin-left: 20px;
  }
}

.overview-btn {
  border: none !important;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(0, 74, 173, 1);
}
.edit-header {
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 74, 173, 1);
}

.form-label-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .label {
    font-family: "Inter", serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 8px;
    color: rgba(79, 79, 79, 1);
  }

  .label-info {
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(79, 79, 79, 1);
    margin-bottom: 0;
  }
}
</style>
