var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":"12"}},[(_vm.loading)?_c('loader',{attrs:{"loading":_vm.loading,"color":_vm.companyData.companyColor}}):_c('v-col',{attrs:{"sm":"12"}},[_c('button',{staticClass:"tw-flex tw-items-center tw-mt-6 overview-btn",on:{"click":_vm.backToPilots}},[_c('icons',{attrs:{"name":"arrow-back","color":_vm.companyData.companyColor}}),_c('span',{staticClass:"tw-ml-4",style:({ color: _vm.companyData.companyColor })},[_vm._v("All Pilots")])],1),_c('v-col',{staticClass:"tw-bg-white tw-mt-9 tw-rounded-xl lg:tw-pl-16 tw-py-10 lg:tw-pr-40 tw-w-full",attrs:{"sm":"12"}},[_c('div',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between tw-mb-10"},[_c('p',{staticClass:"edit-header",style:({ color: _vm.companyData.companyColor })},[_vm._v(" Edit Pilot ")]),(!_vm.pilotData.fleetAssigned)?_c('button',{on:{"click":_vm.handleShowDelete}},[_c('icons',{attrs:{"name":"trash","color":_vm.companyData.companyColor}})],1):_vm._e()]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updatePilot)}}},[_c('v-row',{staticClass:"upload-div tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Upload Photo")]),_c('p',{staticClass:"label-info"},[_vm._v("Choose an image as a profile")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('button',{staticClass:"button-logo-input",class:_vm.image ? 'border-0' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.onLogoFile()}}},[(_vm.logoImageLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute"},attrs:{"size":100,"width":3,"color":_vm.companyData.companyColor,"indeterminate":""}}):_vm._e(),(_vm.logoImage)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.image,"alt":""}}):(_vm.pilotData.profileImage)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.pilotData.profileImage,"alt":""}}):_c('img',{staticClass:"button-logo-input-img",attrs:{"src":require("../../../assets/camera.png"),"alt":""}})],1),_c('input',{ref:"logoImage",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onLogoImagePicked}})])],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("First Name")]),_c('p',{staticClass:"label-info"},[_vm._v("Type the First Name of the Pilot")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Mason","solo":"","hide-details":"","type":"text","required":"","autofocus":""},model:{value:(_vm.pilotData.firstname),callback:function ($$v) {_vm.$set(_vm.pilotData, "firstname", $$v)},expression:"pilotData.firstname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Last Name")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Last Name of the Pilot ")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"Ryan","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.pilotData.lastname),callback:function ($$v) {_vm.$set(_vm.pilotData, "lastname", $$v)},expression:"pilotData.lastname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Email")]),_c('p',{staticClass:"label-info"},[_vm._v(" Type the Email Address of the Pilot ")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Pilot's Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"placeholder":"ryan@gmail.com","solo":"","hide-details":"","type":"email"},model:{value:(_vm.pilotData.email),callback:function ($$v) {_vm.$set(_vm.pilotData, "email", $$v)},expression:"pilotData.email"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Mobile")]),_c('p',{staticClass:"label-info"},[_vm._v("Type the Mobile Number of the Pilot")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('phone-number',{attrs:{"phone-number-exist":_vm.pilotData.phoneNumber,"required":false},on:{"getNumber":_vm.getNumber}})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Address")]),_c('p',{staticClass:"label-info"},[_vm._v("Enter the address in which the Pilot is located.")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-cursor-pointer",class:classes,on:{"click":_vm.handleShowAddressModal}},[_c('v-text-field',{attrs:{"placeholder":"371,Borno Way, Alagomeji Yaba, Lagos","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.getPilotAddress),callback:function ($$v) {_vm.getPilotAddress=$$v},expression:"getPilotAddress"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"tw-mb-5 tw-flex tw-flex-col lg:tw-flex-row"},[_c('v-col',{attrs:{"sm":"12","lg":"4"}},[_c('div',{staticClass:"form-label-div"},[_c('p',{staticClass:"label"},[_vm._v("Terminal")]),_c('p',{staticClass:"label-info"},[_vm._v(" Pilot Assigned Terminal. ")])])]),_c('v-col',{attrs:{"sm":"12","lg":"8"}},[_c('ValidationProvider',{attrs:{"name":"Terminal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-combobox',{attrs:{"items":_vm.terminals,"placeholder":"Choose Terminal","append-icon":"mdi-chevron-down","item-text":"terminalName","item-value":"id","solo":"","hide-details":"","color":_vm.companyData.companyColor,"disabled":""},model:{value:(_vm.pilotData.terminalAssigned),callback:function ($$v) {_vm.$set(_vm.pilotData, "terminalAssigned", $$v)},expression:"pilotData.terminalAssigned"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"tw-flex tw-w-full tw-justify-end tw-items-center action-btn-div tw-mt-10"},[_c('v-btn',{staticClass:"first-btn",style:({
                color: _vm.companyData.companyColor,
                border: '1px solid' + _vm.companyData.companyColor,
              }),attrs:{"text":""},on:{"click":_vm.backToPilots}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"second-btn",style:({ background: _vm.companyData.companyColor }),attrs:{"text":"","type":"submit","loading":_vm.doneLoading}},[_vm._v(" Update ")])],1)],1)]}}])})],1)],1),_c('delete-modal',{attrs:{"description":"Are you sure you want to delete this pilot","done-loading":_vm.deleteLoading,"dialog":_vm.showDelete},on:{"close":_vm.handleShowDelete,"deleteAction":_vm.deletePilot}}),_c('modal',{attrs:{"section":"custom-address","address":_vm.pilotData.address,"dialog":_vm.showAddressModal},on:{"handleAddress":_vm.handleAddress,"close":_vm.closeHandleModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }